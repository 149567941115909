import { Controller } from "stimulus"

export default class extends Controller {

  connect() {

    this.element.addEventListener('ajax:complete',function (event) {
      const xhr = event.detail[0];

      if ((xhr.getResponseHeader('Content-Type') || '').substring(0, 9) === 'text/html') {
        const referrer = window.location.href;
        const snapshot = Turbolinks.Snapshot.wrap(xhr.response);
        Turbolinks.controller.cache.put(referrer, snapshot);
        Turbolinks.visit(referrer, { action: 'restore' });
      }

    }, false);

  }
}
