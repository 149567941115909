import { Controller } from 'stimulus';
import ahoy from 'ahoy.js';

export default class extends Controller {
  static targets = ['cropProductivity', 'transportation', 'tonsToStoreInGrainBags'];

  connect() {
    this.checkServiceValue();
    this.checkTransportationValue();
    this.listenChangesAndReport();
  }

  checkServiceValue() {
    const service = this.getSelectedInput('quote[service]');
    if(service) {
      this.showOrHideTranportation(service);
      this.showOrHideCropProductivity(service);
    }
  }

  checkTransportationValue(event) {
    const transportation = this.getSelectedInput('quote[transportation]');
    if(transportation) {
      this.showOrHideTonsToStoreInGrainBags(transportation);
    }
  }


  listenChangesAndReport() {
    this.getAllInputs().forEach((input) => {

      input.addEventListener && input.addEventListener('blur', function(evt) {
        console.log('blur', evt.target.name, evt.target.value)
        ahoy.track('Edit input', {
          input: evt.target.name,
          value: evt.target.value,
        });
      });

      input.addEventListener && input.addEventListener('click', function(evt) {
        console.log('click', evt.target.name, evt.target.value)
        ahoy.track('Edit input', {
          input: evt.target.name,
          value: evt.target.value,
        });
      });

    });
  }

  showOrHideCropProductivity(service) {
    this.showOrHide(this.cropProductivityTarget,
                    service === 'harvest');
  }

  showOrHideTranportation(service) {
    this.showOrHide(this.transportationTarget,
                    service === 'harvest');
  }

  showOrHideTonsToStoreInGrainBags(transportation) {
    this.showOrHide(this.tonsToStoreInGrainBagsTarget,
                    transportation === 'with_transportation');
  }

  showOrHide(element, condition) {
    if(condition) {
      this.showElement(element);
    }
    else {
      this.hideElement(element);
    }
  }

  showElement(element) {
    element.classList.remove('hidden');
    element.classList.add('block');
    element.required = true;
  }

  hideElement(element) {
    element.classList.remove('block');
    element.classList.add('hidden');
    element.required = false;
  }

  getSelectedInput(name) {
    const el = document.querySelector('input[name="' + name + '"]:checked');
    if(el) {
      return el.value;
    }
    return null;
  }

  getAllInputs() {
    let elements;

    elements = document.quote.querySelectorAll("input[type='number']");
    const result = [];
    for(let input in elements) {
      result.push(elements[input]);
    }

    elements = document.quote.querySelectorAll("input[type='text']");
    for(let input in elements) {
      result.push(elements[input]);
    }

    elements = document.quote.querySelectorAll("input[type='email']");
    for(let input in elements) {
      result.push(elements[input]);
    }

    elements = document.quote.querySelectorAll("input[type='radio']");
    for(let input in elements) {
      result.push(elements[input]);
    }

    elements = document.quote.querySelectorAll("select");
    for(let input in elements) {
      result.push(elements[input]);
    }

    elements = document.quote.querySelectorAll("textarea");
    for(let input in elements) {
      result.push(elements[input]);
    }

    return result;
  }



/*

  validateAll(ev) {
    const errors = {};
    const inputs = this.getInputs();
    console.log('values', values);

    if(!inputs.service.value) {
      errors.service = 'Por favor selecciona un servicio';
      // inputs.service.
    }
    if(!inputs.crop.value) {
      errors.crop = 'Por favor selecciona el cultivo';

    }
    if(!inputs.has.value) {
      errors.has = 'Por favor ingresa la cantidad de hectareas';

    }
    if(!inputs.location.value) {
      errors.state = 'Por favor ingresa la ubicación';

    }
    if(!inputs.state.value) {
      errors.state = 'Por favor selecciona la provincia';

    }
    if(!inputs.contact_method.value) {
      errors.contact_method = 'Por favor selecciona el método de contacto preferido';

    }
    if(!inputs.phone_number.value) {
      errors.phone_number = 'Por favor ingresa un número de teléfono';

    }

    if(inputs.contact_method.value === 'email' && !inputs.email.value) {
      errors.phone_number = 'Por favor ingresa un correo electrónico';

    }

    if(inputs.service.value === 'harvest' && !inputs.crop_productivity.value) {
      errors.crop_productivity = 'Por favor ingresa el rinde aproximado'

    }
    if(inputs.service.value === 'harvest' && !inputs.transportation.value) {
      errors.transportation = 'Por favor indique si necesita flete'

    }

    if(inputs.service.value === 'harvest' &&
       inputs.transportation.value === 'yes' &&
       !inputs.tons_to_store_in_grain_bags.value
       ) {
      errors.tons_to_store_in_grain_bags = 'Por favor ingrese la cantidad de toneladas a embolsar';

    }

    if(Object.keys(errors).length !== 0) {
      console.log('errors', errors)
      ev.preventDefault();
    }
  }

  validateInput(event) {
    console.log('validateInput', event.target.name);
  }

  getInputs() {
    const inputs = {
      service: this.getInput('service'),
      crop: this.getInput('crop'),
      has: this.getInput('has'),
      crop_productivity: this.getInput('crop_productivity'),
      transportation: this.getInput('transportation'),
      tons_to_store_in_grain_bags: this.getInput('tons_to_store_in_grain_bags'),
      location: this.getInput('location'),
      state: this.getInput('state'),
      service_date: this.getInput('service_date'),
      contact_method: this.getInput('contact_method'),
      name: this.getInput('name'),
      email: this.getInput('email'),
      phone_number: this.getInput('phone_number'),
      comments: this.getInput('comments'),
    };

    return inputs;
  }

  getInput(name) {
    // return document.quote['quote[' + name + ']'];
    // return document.getElementById("new_quote").elements[name];
    // return document.querySelector('input[name="quote[crop]"]')
    return document.querySelector('input[name="quote[' + name + ' ]"]');
  }


  validate_service() {

  }
// document.querySelector('input[name="quote[name]"]').closest('.form-group').querySelector('label')


  // document.querySelector('input[name="quote[crop]"]').closest('.form-group')
  // referenceNode.parentNode.insertBefore(newNode, referenceNode.nextSibling);
  // <div class="invalid-feedback d-block">Método de contacto no puede estar en blanco</div>
*/
}
